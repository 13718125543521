#calculator {
   
    padding: 1.5vh;
    border: 5px solid #666666;
    border-radius: 15px;
    margin: 0;
    z-index: 6000;
    background-color: white;
}

#calculator .box {
    text-align:right;
    font-size: 3vh;
    height: 3vh;
    line-height: 3vh;
    padding: 1em;
    border: 2px solid #e68a00;
    color: black;
    border-radius: 15px;
    background-color: black;
    margin-bottom: 5px;
    padding-bottom: 35px;
}

#calculator #last_operation_history {
    height: 1.8vh;
    font-size: 1.8vh;
    text-align:right;
    padding-right: 1.5vh;
    color: rgb(177, 176, 176);
    background-color: white;
}

#calculator button {
    width: 3rem;
    height: 3rem;
    font-weight:bold;
    font-size: 3vh;
    color: white;
    background-color: #666666;
    border-radius: 50%;
    border: 1px solid #333333;
    margin: 2px;
}

#calculator #backspace_btn {
    background-image: url("../../../res/images/back_remove.png");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 1.7em;
}

#calculator button:hover {
    background-color: #999999;
    cursor: pointer;
}

#calculator .operator {
    background-color: #e68a00;
}

#calculator .operator:hover {
    background-color: #ffd11a;
}

#calculator #equal_sign {
    width: 100%;
    border-radius: 15px;
}
